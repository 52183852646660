<template>
  <div class="main-wrap">
    <page-bread />
    <el-container class="home-container">
      <el-aside width="292px">
        <page-aside>
          <el-menu :default-active="activeIndex" router>
            <el-menu-item
              :index="item.path"
              v-for="item in menuList"
              :key="item.path"
              >{{ item.name }}</el-menu-item
            >
          </el-menu>
        </page-aside>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import pageAside from "@/components/pageAside.vue";
export default {
  name: "",
  data() {
    return {
      activeIndex: this.$route.path,
      menuList: [
        {
          name: "在线捐款",
          path: "/donation/online",
        },
        {
          name: "我要开票",
          path: "/donation/offline",
        },
        {
          name: "捐款查询",
          path: "/donation/query",
        },
        {
          name: "爱心企业",
          path: "/donation/enterprise",
        },
        {
          name: "爱心个人",
          path: "/donation/individual",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  components: {
    pageAside,
  },
  watch: {
    "$route.path"(val) {
      this.activeIndex = val;
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep.el-main {
  border-left: 8px solid #f4f4f4;
  min-height: 700px;
}
</style>